<template>
    <transition name="fade">
      <div>

        <div class="card mb-4" style="width: 100%;" v-loading="state.loaderEnabled">
          <div class="card-body pt-6">
            <div class="row row-user">
                <div class="col-sm-5 col-xxl-3 p-5 label-modal"><span style="font-size: 15px;"><strong>Réseau source</strong></span></div>
                <div class="col"> 
                  <el-select v-model="form.sourceNetwork" default-first-option placeholder="Veuillez choisir un réseau source" class="m-2" style='float: left; width: 100%'>
                    <el-option v-for="item in state.networksSource"  :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </div>
            </div>
          </div>
        </div>

        <Firewall v-if="form.sourceNetwork" :idNetwork="form.sourceNetwork" :key="form.sourceNetwork" :editMode="true" />

      </div>
    </transition>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, onMounted, reactive } from "vue";
  import { useStore } from "vuex";
  import Firewall from "@/views/still/meraki/firewall.vue";
  

  import mAxiosApi from "@/api";

  export default defineComponent({
    name: "Overview",
    components: {Firewall},
    setup() {

      const store = useStore();
      const state = reactive({
        loaderEnabled: false,
        networksSource: [] as any,
      });

      const form = reactive({}) as any;

      onMounted(async () => {  
        if (!store.getters.getOrg.idOrg) return false;
        buildList();
      });

      const buildList = async () => {

        state.loaderEnabled = true;

        const mNetworksSource = await getAxios("/networksByTag?tag=SOURCE_CLONE&organization_id=" + store.getters.getOrg.idOrg);

        state.networksSource = mNetworksSource;
        if(state.networksSource && state.networksSource[0])  form.sourceNetwork = state.networksSource[0].value;
       
        state.loaderEnabled = false;
        
      }
 

      return {
        state,
        store,
        form
      };
    },
  });
  
  export async function getAxios(request: string): Promise<any> {
    const response = await mAxiosApi.get(request);
    return response.data;
  }
  </script>