
  import { defineComponent, ref, onMounted, reactive } from "vue";
  import { useStore } from "vuex";
  import Firewall from "@/views/still/meraki/firewall.vue";
  

  import mAxiosApi from "@/api";

  export default defineComponent({
    name: "Overview",
    components: {Firewall},
    setup() {

      const store = useStore();
      const state = reactive({
        loaderEnabled: false,
        networksSource: [] as any,
      });

      const form = reactive({}) as any;

      onMounted(async () => {  
        if (!store.getters.getOrg.idOrg) return false;
        buildList();
      });

      const buildList = async () => {

        state.loaderEnabled = true;

        const mNetworksSource = await getAxios("/networksByTag?tag=SOURCE_CLONE&organization_id=" + store.getters.getOrg.idOrg);

        state.networksSource = mNetworksSource;
        if(state.networksSource && state.networksSource[0])  form.sourceNetwork = state.networksSource[0].value;
       
        state.loaderEnabled = false;
        
      }
 

      return {
        state,
        store,
        form
      };
    },
  });
  
  export async function getAxios(request: string): Promise<any> {
    const response = await mAxiosApi.get(request);
    return response.data;
  }
  